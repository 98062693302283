import { memo, useCallback, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, ButtonKind, Spinner } from '@gbg/gbgcomponentlibrary_react';
import { useGetDatasetsQuery, useLazyGetDatasetValidationSchemaQuery } from '../../api/datasets';
import { usePutProcessStatusMutation, usePutDatasetMappingsMutation } from '../../api/jobs';
import { Dataset } from '../../models/Dataset';
import { JobStatus } from '../../models/job';
import DatasetMappingSection from '../../components/input/DatasetMappingSection';
import CustomSpinner from '../../components/CustomSpinner';

export const PreflightJob = () => {
  const navigate = useNavigate();
  const { jobId } = useParams<{ jobId: string }>();

  const [selectedDataset, setSelectedDataset] = useState<Dataset | undefined>(undefined);
  const [putDatasetMapping, { isLoading: isPuttingDatasetMapping }] = usePutDatasetMappingsMutation();
  const [putProcessStatus, { isLoading: isUpdatingStatus }] = usePutProcessStatusMutation();
  const { data: datasets, isFetching: isFetchingDatasets } = useGetDatasetsQuery();
  const [getDatasetValidationSchema, { isLoading: isGettingSchema }] = useLazyGetDatasetValidationSchemaQuery();
  const methods = useForm();

  const onSubmit = useCallback(
    methods.handleSubmit(async (data: any, e: any) => {
      e.preventDefault();
      if (!jobId || !selectedDataset) return;

      data.dataset = {
        id: selectedDataset.id,
        version: selectedDataset.version,
        pci_handler: selectedDataset.pci_handler,
      };

      // Save the mappings
      let hasError = false;
      await putDatasetMapping({
        jobId: jobId,
        mappings: data,
      })
        .unwrap()
        .catch(() => {
          hasError = true;
        });

      if (hasError) return;
      const validationSchema = await getDatasetValidationSchema({
        id: selectedDataset.id,
        version: selectedDataset.version,
      }).unwrap();

      await putProcessStatus({
        jobId: jobId,
        status: JobStatus.pre_flight_requested,
        datasetValidationSchema: validationSchema.schema,
      })
        .unwrap()
        .catch(() => {
          hasError = true;
        });

      //Return to jobs list once both api calls succeeded
      if (!hasError) navigate('/jobs');
    }),
    [selectedDataset, putDatasetMapping, putProcessStatus, jobId, methods.handleSubmit],
  );

  const returnToJobs = useCallback(() => {
    navigate('/jobs');
  }, []);

  return isFetchingDatasets ? (
    <CustomSpinner />
  ) : (
    <>
      <br />
      {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
      {/* @ts-ignore */}
      <FormProvider {...methods}>
        <form onSubmit={onSubmit} data-testid="preflight-form" className="preflight-form">
          <DatasetMappingSection
            datasets={datasets}
            setSelectedDataset={setSelectedDataset}
            isSubmittingForm={isPuttingDatasetMapping}
          ></DatasetMappingSection>

          <br />
          {selectedDataset && (
            <Button
              type="submit"
              kind={ButtonKind.Primary}
              className="pull-left"
              active={isPuttingDatasetMapping || isUpdatingStatus || isGettingSchema}
            >
              {(isPuttingDatasetMapping || isUpdatingStatus || isGettingSchema) && <Spinner />}
              Confirm
            </Button>
          )}

          <Button type="button" kind={ButtonKind.Secondary} className="float-end" onClick={returnToJobs}>
            Cancel
          </Button>
        </form>
      </FormProvider>
    </>
  );
};

export default memo(PreflightJob);
