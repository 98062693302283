import React, { useEffect } from 'react';
import { get, useFormContext } from 'react-hook-form';
import { Label, FormGroup } from '@gbg/gbgcomponentlibrary_react';

interface ITextInput {
  labelText: string;
  name: string;
  defaultValue?: string;
  required?: boolean;
  disabled?: boolean;
}

const TextInput: React.FC<ITextInput> = ({
  name,
  labelText,
  defaultValue = '',
  required = false,
  disabled = false,
}) => {
  const {
    register,
    setValue,
    formState: { errors },
  } = useFormContext();

  useEffect(() => {
    register(name as `${string}`, {
      required: required ? 'This field is required' : false,
    });
    setValue(name as `${string}`, defaultValue);
  }, [register, setValue, name, required, defaultValue]);

  const error = get(errors, name);
  return (
    <FormGroup className="form-floating">
      <input
        type="text"
        name={name}
        id={name}
        defaultValue={defaultValue}
        disabled={disabled}
        data-testid={name}
        className="form-control"
        onChange={e => setValue(name as `${string}`, e.target.value)}
        onBlur={e => setValue(name as `${string}`, e.target.value.trim())}
      />
      <Label htmlFor={name}>
        <div>
          <span className={required ? 'required' : ''}>{labelText}</span>
        </div>
      </Label>
      {error && required && <div className="error-text">{error.message || 'This field is required.'}</div>}
    </FormGroup>
  );
};

export default React.memo(TextInput);
