import { memo, useCallback, useState } from 'react';
import { v4 as uuid } from 'uuid';
import TextInput from './TextInput';
import { Dataset, Status, ValidationSchema, ValidationSchemaJSON } from '../../models/Dataset';
import CustomSpinner from '../CustomSpinner';
import { AlertBar, AlertBarText, AlertBarType, Label, Select } from '@gbg/gbgcomponentlibrary_react';
import { useLazyGetDatasetValidationSchemaQuery } from '../../api/datasets';
import { useFormContext } from 'react-hook-form';

export interface DatasetMappingSectionProps {
  setSelectedDataset: React.Dispatch<React.SetStateAction<Dataset | undefined>>;
  datasets: Dataset[] | undefined;
  isSubmittingForm: boolean;
}
interface IDynamicFormFields {
  fieldName: string;
  required: boolean;
}

const DatasetMappingSection: React.FC<DatasetMappingSectionProps> = ({
  setSelectedDataset,
  datasets,
  isSubmittingForm,
}: DatasetMappingSectionProps) => {
  const [credentials, setCredentials] = useState<IDynamicFormFields[] | null>(null);
  const [customAttributes, setCustomAttributes] = useState<IDynamicFormFields[] | null>(null);
  const [getDatasetValidationSchema, { isFetching }] = useLazyGetDatasetValidationSchemaQuery();
  const [showPciAlert, setShowPciAlert] = useState<boolean>(false);

  const { reset } = useFormContext();

  const extractDynamicFormFields = (json: any, key: string): IDynamicFormFields[] => {
    const fields: IDynamicFormFields[] = [];

    const items = json?.properties?.datasets?.items?.properties;
    if (!items) {
      return fields;
    }

    const targetProperties = items[key]?.properties;
    if (!targetProperties) {
      return fields;
    }

    const requiredFields = items[key]?.required || [];

    for (const [fieldName] of Object.entries(targetProperties)) {
      fields.push({
        fieldName,
        required: requiredFields.includes(fieldName),
      });
    }

    return fields;
  };

  const buildDynamicFields = (fields: IDynamicFormFields[] | null, type: string, title: string) => {
    if (!fields || fields.length === 0) {
      return;
    }

    return (
      <div className="form-input-group">
        <div className="input-group-title">
          <div className="title-container">
            <span>{title}</span>
          </div>
          <div className="row">
            {fields.map((field: IDynamicFormFields) => {
              return (
                <div key={uuid()} className="col-md-6 col-xs-12">
                  <TextInput
                    labelText={field.fieldName}
                    name={`${type}.${field.fieldName}`}
                    required={field.required}
                  />
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  };

  const buildOptions = datasets?.map((dataset: Dataset) =>
    dataset.status === Status.live || dataset.status === Status.testing ? (
      <option key={dataset.id + '-' + dataset.version} value={dataset.id + '-' + dataset.version}>
        {dataset.id} {dataset.description} version {dataset.version} {dataset.pci_handler && '(PCI Handler)'}
      </option>
    ) : null,
  );

  const handleChangeDataset = useCallback(
    (e: React.ChangeEvent<HTMLSelectElement>) => {
      const value = e.currentTarget.value;
      if (!value) return;

      const selectedDataset = datasets?.find((dataset: any) => dataset.id + '-' + dataset.version === value);
      if (!selectedDataset) return;
      setShowPciAlert(selectedDataset.pci_handler);
      setSelectedDataset(selectedDataset);
      getDatasetValidationSchema({
        id: selectedDataset.id,
        version: selectedDataset.version,
      })
        .unwrap()
        .then((result: ValidationSchema) => {
          const json: ValidationSchemaJSON = JSON.parse(result.schema);
          reset();
          setCredentials(extractDynamicFormFields(json, 'credentials'));
          setCustomAttributes(extractDynamicFormFields(json, 'customAttributes'));
        });
    },
    [datasets, getDatasetValidationSchema, setSelectedDataset, setCredentials, setCustomAttributes],
  );

  return (
    <div data-testid="DatasetMappingSection">
      <div className="row">
        <div className="col-md-12">
          <Label>Dataset selection</Label>
          <Select data-testid="dataset" onChange={(e: React.ChangeEvent<HTMLSelectElement>) => handleChangeDataset(e)}>
            <option value="" hidden>
              Select a Dataset...
            </option>
            {buildOptions}
          </Select>
        </div>
      </div>

      {showPciAlert && (
        <AlertBar data-testid="pci-alert" className="m-3" icon="alertTriangle24" type={AlertBarType.Warn}>
          <AlertBarText
            title="PCI Handler Dataset"
            text="This is a PCI handler dataset. It cannot be used in live mode."
          />
        </AlertBar>
      )}

      {isFetching && !isSubmittingForm ? (
        <CustomSpinner />
      ) : (
        <div className="dynamic-fields">
          {buildDynamicFields(credentials, 'credentials', 'Credentials')}
          {buildDynamicFields(customAttributes, 'customAttributes', 'Custom Attributes')}
        </div>
      )}
    </div>
  );
};
export default memo(DatasetMappingSection);
