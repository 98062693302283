import { BaseQueryApi } from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import { createApi, BaseQueryFn } from '@reduxjs/toolkit/query/react';
import axios, { AxiosError, Method } from 'axios';

import { env } from '../env';
import { clearAuth } from '../features/ping/pingSlice';
import { authorize } from '../features/ping/utils';
import { RootState } from '../app/store';
import { setError } from '../features/error/errorSlice';
import { v4 as uuid } from 'uuid';

const client = axios.create({ baseURL: env.REACT_APP_EVALUATOR_API_BASE_URL ?? '' });

const axiosBaseQuery =
  (
    { baseUrl }: { baseUrl: string } = { baseUrl: '' },
  ): BaseQueryFn<{ url: string; method: Method; data?: unknown }, BaseQueryApi, unknown> =>
  async ({ url, method, data }, { dispatch, getState }) => {
    const { accessToken } = (getState() as RootState).ping;
    try {
      if (!accessToken) {
        const result = await client({
          method,
          url: baseUrl + url,
          data,
        });
        return result.data;
      }

      const response = await client({
        method,
        url: baseUrl + url,
        data,
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'gbg-correlation-id': uuid(),
        },
      });

      return { data: response.data ? response.data : null };
    } catch (axiosError) {
      const error = axiosError as AxiosError;
      if (error.response?.status === 401) {
        dispatch(clearAuth());
        authorize();
      }
      if (error.response?.status === 403) {
        window.location.replace('/error/403');
      }
      let errorMessage = error.message;
      if (error?.response?.data) {
        if ((error.response.data as any)['message']) {
          // TODO, response should not be like {message:error}, should fix error handling
          errorMessage = (error.response.data as any)['message'];
        } else if (typeof error.response.data === 'string') {
          errorMessage = error.response.data;
        }
      }

      dispatch(setError(errorMessage));
      if (error.response) {
        const { data }: { data: unknown } = error.response;
        return { error: { ...(data as Record<string, unknown>), status: error.response.status } };
      }
      return {
        error: { status: undefined },
      };
    }
  };

export const evaluatorApi = createApi({
  reducerPath: 'evaluatorApi',
  tagTypes: ['Job', 'JobPci'],
  baseQuery: axiosBaseQuery({
    baseUrl: env.REACT_APP_EVALUATOR_API_BASE_URL ?? '',
  }),
  endpoints: () => ({}),
});

export const builderApi = createApi({
  reducerPath: 'builderApi',
  tagTypes: ['Dataset'],
  baseQuery: axiosBaseQuery({
    baseUrl: env.REACT_APP_BUILDER_API_BASE_URL ?? '',
  }),
  endpoints: () => ({}),
});
