import React, { useCallback, useMemo, useState } from 'react';
import './Job.css';
import { useGenerateUploadUrlMutation, useCreateJobMutation } from '../../api/jobs';
import { useNavigate } from 'react-router-dom';
import { Button, ButtonKind, toast } from '@gbg/gbgcomponentlibrary_react';
import { TOAST_MSG } from '../../models/toast';
import { useAppDispatch } from '../../app/hooks';
import { setError } from '../../features/error/errorSlice';
import DraggableFileInput from '../../components/input/DraggableFileInput';
import TextInput from '../../components/input/TextInput';
import { FormProvider, useForm } from 'react-hook-form';

const AddJob = () => {
  const inputRef = React.useRef<HTMLInputElement>(null);
  const [generateUploadUrl, { isLoading: isUploadingFile }] = useGenerateUploadUrlMutation();
  const [postJob, { isLoading: isPostingJob }] = useCreateJobMutation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [displayFileNameInput, setDisplayFileNameInput] = useState<boolean>(false);
  const form = useForm();

  const hash = useMemo(() => {
    return Math.random().toString(36).substring(2, 10);
  }, []);

  const uploadFile = async (url: string, file: File) => {
    try {
      await fetch(url, {
        method: 'PUT',
        body: file,
      });
      return true;
    } catch {
      dispatch(setError('Uploading file failed.Please try later.'));
      return false;
    }
  };

  // triggers when file is dropped
  const handleFileUploadChange = async function (e: React.ChangeEvent<HTMLInputElement> | React.DragEvent) {
    e.preventDefault();
    e.stopPropagation();
    if (isUploadingFile || isPostingJob) return false;
    const files = 'dataTransfer' in e ? e.dataTransfer.files : e.target.files;
    if (inputRef.current) inputRef.current.files = files;
    await createJob();
  };

  const getSelectedFile = () => {
    const files = inputRef.current?.files;
    if (!files) return false;
    if (files.length > 1) {
      dispatch(setError('Only one file can be uploaded.'));
      return null;
    }

    return files[0];
  };

  const createJob = async (uniqueFileName?: string) => {
    const file = getSelectedFile();
    if (!file) return false;

    let fileExt: string | undefined = undefined;
    if (file.name.indexOf('.') > 0) fileExt = file.name.split('.').pop();
    else fileExt = '.csv';
    const fileName = `${hash}.${fileExt}`;
    let fileDisplayName = uniqueFileName ?? file.name;
    if (fileDisplayName.indexOf('.') < 0) fileDisplayName = `${fileDisplayName}.${fileExt}`;
    fileDisplayName = fileDisplayName.replace(' ', '');

    generateUploadUrl({ fileName, fileDisplayName })
      .unwrap()
      .then(async result => {
        await uploadFileAndCreateJob(result.jobId, result.url, file, fileName, fileDisplayName);
      })
      .catch(() => {
        setDisplayFileNameInput(true);
      });
  };

  const uploadFileAndCreateJob = async (
    jobId: string,
    fileUrl: string,
    file: File,
    fileName: string,
    fileDisplayName: string,
  ) => {
    const uploadResult = await uploadFile(fileUrl, file);
    if (!uploadResult) return;
    const newJob = await postJob({ fileName, fileDisplayName, jobId }).unwrap();

    if (newJob?.jobId) {
      toast(TOAST_MSG('File uploaded successfully.'));
      navigate('/jobs');
    }
  };

  const onSubmit = useCallback(
    form.handleSubmit(async (data: any) => {
      createJob(data.fileName);
    }),
    [form.handleSubmit, createJob],
  );
  return (
    <>
      <h1>Add Job</h1>

      <DraggableFileInput
        acceptableTypes=".xls,.xlsx,.csv"
        handleFileUploadChange={handleFileUploadChange}
        inputRef={inputRef}
        isUploading={isUploadingFile || isPostingJob}
        name="upload_job_file"
        className={displayFileNameInput ? 'd-none' : ''}
      />
      {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
      {/* @ts-ignore */}
      <FormProvider {...form}>
        <form
          id="form-file-upload"
          onSubmit={onSubmit}
          data-testid="file-name-input-form"
          className={!displayFileNameInput ? 'd-none' : ''}
        >
          <div className="col-md-3">
            <TextInput name="fileName" labelText="File Name" required={true} />
            <Button
              type="submit"
              kind={ButtonKind.Primary}
              className="pull-left"
              active={isUploadingFile || isPostingJob}
              worker={isUploadingFile || isPostingJob}
              data-testid="rename-input-file-btn"
            >
              Confirm
            </Button>
          </div>
        </form>
      </FormProvider>
    </>
  );
};

export default AddJob;
