export interface Dataset {
  id: number;
  description: string;
  version: number;
  country: string;
  provider: string;
  status: Status;
  lastUpdated: string;
  pci_handler: boolean;
}

export enum Status {
  'draft' = 'Draft',
  'testing' = 'Testing',
  'live' = 'Live',
  'retired' = 'Retired',
}

export interface ValidationSchema {
  schema: string;
}
export interface ValidationSchemaJSON {
  properties?: TopLevelProperty;
}

export interface TopLevelProperty {
  datasets?: DatasetProperty;
}

export interface DatasetProperty {
  items?: Item;
}

export interface Item {
  properties?: ItemProperty;
}

export interface ItemProperty {
  credentials?: Credential;
  customAttributes?: CustomAttribute;
}

export interface Credential {
  properties?: object;
}

export interface CustomAttribute {
  properties?: object;
}
